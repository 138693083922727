import { REGISTER_RES, REGISTER_FAIL, RESET_USER, CONFIRM_SIGNUP_RES, CREATE_USER_RES, LIST_USERS } from '../../helpers/constants';
import { register, confirmSignUp, createUser } from '../../api/user/user-api';
import { toast } from '../../helpers/utility';
require('es6-promise').polyfill();

export function RegisterAction(data) {
    return async function (dispatch) {
        const res = await register(data)
        if (!res.data.data.error) {
            dispatch({
                type: REGISTER_RES,
                payload: res.data.data.message
            });
        } else {
            dispatch({
                type: REGISTER_FAIL,
                payload: res.data.data.message
            });
        }
    };
}
export function CreateAction(data) {
    return async function (dispatch) {
        const res = await createUser(data)
        if (!res.data.data.error) {
            console.log(res.data.data.user);
            dispatch({
                type: LIST_USERS,
                payload: res.data.data.user
            });
        }
        else {
            toast(res.data.data.message, 'error')
        }
    };
}

export function ConfirmSignUpAction(token) {
    return async function (dispatch) {
        const res = await confirmSignUp(token)
        // console.log(res);
        if (!res.data.data.error && res.data.data.code === 205) {
            dispatch({
                type: CONFIRM_SIGNUP_RES,
                payload: res.data.data,
                notConfirm: false
            });
        } else if (!res.data.data.error) {
            dispatch({
                type: CONFIRM_SIGNUP_RES,
                payload: res.data.data,
                notConfirm: true
            });
        } else {
            console.log(res);
        }
    };
}
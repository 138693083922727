import { LOGIN_RES, IS_LOGIN, LOGIN_MESSAGE, IN_PROCESS, IS_VERIFY } from '../../helpers/constants';
import { login, verify } from '../../api/user/user-api';
import { setToken, toast } from '../../helpers/utility';
require('es6-promise').polyfill();
export function LoginAction(data) {
    return async function (dispatch) {
        dispatch({
            type: LOGIN_MESSAGE,
            payload: ''
        });
        const res = await login(data)
        dispatch({
            type: IN_PROCESS,
            payload: false
        });
        if (!res.data.data.error && res.data.data && Object.keys(res.data.data).length > 0) {
            dispatch({
                type: LOGIN_RES,
                payload: res.data.data
            });
            dispatch({
                type: IS_LOGIN,
                payload: true
            });
            dispatch({
                type: LOGIN_MESSAGE,
                payload: res.data.message
            });
        } else {
            dispatch({
                type: IS_LOGIN,
                payload: false
            });
            if (Object.keys(res.data.data).length) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    payload: res.data.data.message
                });
            } else {
                dispatch({
                    type: LOGIN_MESSAGE,
                    payload: res.data.message
                });
            }
        }
    };
}
export function VerifyAction(data,user) {
    return async function (dispatch) {
        const res = await verify(data)
        if(res.data.data.status){
            dispatch({
                type: IS_VERIFY,
                payload: { ...user, date: new Date() }
            });
        }else{
            toast('Invalid Code','error')
        }
    }
}
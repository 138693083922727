import React from "react";
import clsx from "clsx";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./three-tabs.scss";
import { connect } from "react-redux";
import store from "../../store";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import {
  TIME_FORMAT,
  DATE_FORMAT,
  CLIENT_NOTES,
} from "../../helpers/constants";
import {
  getClientAction,
  CreateClientNotes,
  GetClientNotes,
} from "../../actions/client-action";
import { getAge, getUser, toast } from "../../helpers/utility";
import { TO_SERVICE_VIEW, TO_DIVERSION_CENTRE } from "../../helpers/constants";

import TextField from "@material-ui/core/TextField";
import  {Autocomplete,
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";

import Styles from "../shared/css/default.module.css";
import client from "../../reducers/client-reducer";

const from = 0;
const to = 50;

class ThreeTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      client: {},
      pastactivity: [],
      notes: "",
      clientNotes: [],
      activeTab: 1,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.pastActivities = this.pastActivities.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNotesClose = this.handleNotesClose.bind(this);
    this.onChangeMedical = this.onChangeMedical.bind(this);
    this.wordWrap = this.wordWrap.bind(this);
    this.fullNote = this.fullNote.bind(this);
  }

  wordWrap(str) {
    var wrap = str;
    if (str.length > to) {
      wrap = str.substring(from, to) + "...";
    }
    return wrap;
  }

  fullNote(notes) {
    this.setState({ notesToShow: notes, notesPopup: true });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      store.dispatch(getClientAction(this.props.match.params.id));
      store.dispatch(GetClientNotes(this.props.match.params.id));
    }
  }
  componentWillUnmount() {
    store.dispatch({
      type: CLIENT_NOTES,
      payload: [],
    });
  }
  pastActivities() {
    let pastActivities = [];
    if (this.state.client.CellVisits && this.state.client.CellVisits.length)
      this.state.client.CellVisits.map((elem) => {
        pastActivities.push({
          name: "CellVisits",
          createdAt: elem.createdAt,
          id: elem.id,
        });
      });
    if (
      this.state.client.CommunityPatrols &&
      this.state.client.CommunityPatrols.length
    )
      this.state.client.CommunityPatrols.map((elem) => {
        pastActivities.push({
          name: "CommunityPatrols",
          createdAt: elem.createdAt,
          id: elem.id,
        });
      });
    if (
      this.state.client.DiversionCenters &&
      this.state.client.DiversionCenters.length
    )
      this.state.client.DiversionCenters.map((elem) => {
        pastActivities.push({
          name: "DiversionCenters",
          createdAt: elem.createdAt,
          id: elem.id,
        });
      });

    this.setState({
      pastactivity: pastActivities.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.updated !== prevProps.updated) {
      //
    }

    if (
      JSON.stringify(prevProps.client) !== JSON.stringify(this.props.client)
    ) {
      if (this.props.match.params.id) {
        this.setState(
          {
            client: {
              ...this.props.client,
              dob: this.props.client.dob
                ? this.props.client.dob.split("T")[0]
                : "",
              endDate: this.props.client.endDate
                ? this.props.client.endDate.split("T")[0]
                : "",
              startDate: this.props.client.startDate
                ? this.props.client.startDate.split("T")[0]
                : "",
            },
          },
          () => { }
        );
        this.pastActivities();
        // toast('Success')
      }
    }

    if (JSON.stringify(prevProps.notes) !== JSON.stringify(this.props.notes)) {
      this.setState({ clientNotes: this.props.notes });
    }
    if (JSON.stringify(prevProps.note) !== JSON.stringify(this.props.note)) {
      store.dispatch(GetClientNotes(this.props.match.params.id));
    }
  }
  onChangeMedical(e) {
    let name = e.target.name;
    this.setState({ notes: e.target.value }, () => {
      // store.dispatch({
      //     type: CLIENT_BACKGROUND,
      //     payload: this.state.clientBackground
      // });
      // store.dispatch({
      //     type: IS_CLIENT_BACKGROUND,
      //     payload: true
      // });
    });
  }

  handleSave(flag) {
    let type = flag === "med" ? "medical" : "general";
    if (this.state.notes) {
      let notes = {
        notes: this.state.notes,
        notesType: type,
        clientId: this.props.match.params.id,
      };
      store.dispatch(CreateClientNotes(notes));
      this.setState({ show: false, show2: false });
    } else {
      toast("Enter Notes", "error");
    }
  }

  handleClose() {
    this.setState({ show: false, show2: false });
  }

  handleNotesClose() {
    this.setState({ notesPopup: false, notesToShow: "" });
  }

  handleSelect(key) {
    this.setState({ key: key });
  }

  handleTab(e) {
    this.setState({ activeTab: e });
  }

  // render() {
  //   return (
  //     <div className="tabs-section full-width mt-4">
  //       <Tabs defaultActiveKey="past-activities" id="uncontrolled-tab-example">
  //         {getAge(this.props.client) && (
  //           <Tab
  //             eventKey="past-activities"
  //             title={<span>Past Activities </span>}
  //             className="tab-table"
  //           >
  //             <Row>
  //               <Col md={12}>
  //                 <Table className="text-center">
  //                   <thead>
  //                     <tr>
  //                       <th>DATE</th>
  //                       <th>ACTIVITY</th>
  //                       <th>DETAILS</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {this.state.pastactivity.slice(0, 3).map((elem) => (
  //                       <tr>
  //                         <td>
  //                           {moment(elem.createdAt).format(DATE_FORMAT)}:{" "}
  //                           {moment(elem.createdAt).format(TIME_FORMAT)}
  //                         </td>
  //                         <td>
  //                           {elem.name === "CellVisits"
  //                             ? "Cell Visit"
  //                             : elem.name === "CommunityPatrols"
  //                             ? "Community Patrol"
  //                             : "Diversion Centre"}
  //                         </td>
  //                         <td className="p-0">
  //                           <Link
  //                             to={
  //                               TO_SERVICE_VIEW +
  //                               "/" +
  //                               elem.name +
  //                               "/" +
  //                               elem.id
  //                             }
  //                           >
  //                             <button
  //                               type="submit"
  //                               className="main-btn btn btn-primary view-btn"
  //                             >
  //                               VIEW
  //                             </button>
  //                           </Link>
  //                         </td>
  //                       </tr>
  //                     ))}
  //                   </tbody>
  //                 </Table>
  //               </Col>
  //             </Row>
  //           </Tab>
  //         )}
  //         {getUser().Role && getUser().Role.medicalHistory && (
  //           <Tab
  //             eventKey="medical-obsevations"
  //             title={<span>Medical Observations</span>}
  //             className="tab-table"
  //           >
  //             <Row>
  //               <Col md={3}></Col>
  //               <Col md={3}></Col>
  //               <Col md={3}></Col>
  //               <Col md={3}>
  //                 {this.props.match.params.id && (
  //                   <button
  //                     type="button"
  //                     onClick={(e) => this.setState({ show: true, notes: "" })}
  //                     className="main-btn btn btn-primary orange-btn view-btn pull-right"
  //                   >
  //                     Add New
  //                   </button>
  //                 )}
  //               </Col>
  //               <Col md={12}>
  //                 <Table className="text-center">
  //                   <thead>
  //                     <tr>
  //                       <th>DATE</th>
  //                       <th>TIME</th>
  //                       <th>NOTE</th>
  //                       <th>CREATED BY</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {this.props.notes &&
  //                       this.props.notes.map((note) => (
  //                         <>
  //                           {note.notesType == "medical" && (
  //                             <tr>
  //                               <td>
  //                                 {moment(note.createdAt).format(DATE_FORMAT)}
  //                               </td>
  //                               <td>
  //                                 {moment(note.createdAt).format(TIME_FORMAT)}
  //                               </td>
  //                               <td onClick={() => this.fullNote(note.notes)}>
  //                                 {this.wordWrap(note.notes)}
  //                               </td>
  //                               <td className="p-0">{note.User.name}</td>
  //                             </tr>
  //                           )}
  //                         </>
  //                       ))}
  //                   </tbody>
  //                 </Table>
  //               </Col>
  //             </Row>
  //           </Tab>
  //         )}
  //         {getUser().Role && getUser().Role.observations && (
  //           <Tab
  //             eventKey="general-observations"
  //             title={<span>File Notes</span>}
  //             className="tab-table"
  //           >
  //             <Row>
  //               <Col md={3}></Col>
  //               <Col md={3}></Col>
  //               <Col md={3}></Col>
  //               <Col md={3}>
  //                 {this.props.match.params.id && (
  //                   <button
  //                     type="button"
  //                     onClick={(e) => this.setState({ show2: true, notes: "" })}
  //                     className="main-btn btn btn-primary orange-btn view-btn pull-right"
  //                   >
  //                     Add New
  //                   </button>
  //                 )}
  //               </Col>
  //               <Col md={12}>
  //                 <Table className="text-center">
  //                   <thead>
  //                     <tr>
  //                       <th>DATE</th>
  //                       <th>TIME</th>
  //                       <th>NOTE</th>
  //                       <th>CREATED BY</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {this.props.notes &&
  //                       this.props.notes.map((note) => (
  //                         <>
  //                           {note.notesType == "general" && (
  //                             <tr>
  //                               <td>
  //                                 {moment(note.createdAt).format(DATE_FORMAT)}
  //                               </td>
  //                               <td>
  //                                 {moment(note.createdAt).format(TIME_FORMAT)}
  //                               </td>
  //                               <td onClick={() => this.fullNote(note.notes)}>
  //                                 {this.wordWrap(note.notes)}
  //                               </td>
  //                               <td className="p-0">{note.User.name}</td>
  //                             </tr>
  //                           )}
  //                         </>
  //                       ))}
  //                   </tbody>
  //                 </Table>
  //               </Col>
  //             </Row>
  //           </Tab>
  //         )}
  //       </Tabs>
  //       <Row>
  //         <Col md={12}>
  //           <Modal
  //             className="form-consent"
  //             show={this.state.show}
  //             // onHide={close}
  //             aria-labelledby="contained-modal-title"
  //           >
  //             <Modal.Body>
  //               <Container>
  //                 <Row className="d-flex align-items-center">
  //                   <Col md={12}>
  //                     <div className="form-consent-heading">
  //                       <Form.Label className="text-label">
  //                         Write Medical Observation
  //                       </Form.Label>
  //                     </div>
  //                   </Col>
  //                   <Col md={12}>
  //                     <Form.Group controlId="exampleForm.ControlTextarea1">
  //                       <Form.Control
  //                         value={this.state.notes}
  //                         onChange={(e) => this.onChangeMedical(e)}
  //                         name="notes"
  //                         as="textarea"
  //                         rows={2}
  //                       />
  //                     </Form.Group>
  //                   </Col>
  //                 </Row>

  //                 <Row className="d-flex align-items-center">
  //                   <Col md={12}>
  //                     <Button
  //                       onClick={this.handleClose}
  //                       className="main-btn default-btn btn-right"
  //                     >
  //                       {" "}
  //                       Close
  //                     </Button>
  //                     <Button
  //                       onClick={() => this.handleSave("med")}
  //                       className="main-btn default-btn btn-right"
  //                     >
  //                       {" "}
  //                       Save
  //                     </Button>
  //                   </Col>
  //                 </Row>
  //               </Container>
  //             </Modal.Body>
  //           </Modal>
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col md={12}>
  //           <Modal
  //             className="form-consent"
  //             show={this.state.show2}
  //             // onHide={close}
  //             aria-labelledby="contained-modal-title"
  //           >
  //             <Modal.Body>
  //               <Container>
  //                 <Row className="d-flex align-items-center">
  //                   <Col md={12}>
  //                     <div className="form-consent-heading">
  //                       <Form.Label className="text-label">
  //                         Write Note
  //                       </Form.Label>
  //                     </div>
  //                   </Col>
  //                   <Col md={12}>
  //                     <Form.Group controlId="exampleForm.ControlTextarea1">
  //                       <Form.Control
  //                         value={this.state.notes}
  //                         onChange={(e) => this.onChangeMedical(e)}
  //                         name="notes"
  //                         as="textarea"
  //                         rows={2}
  //                       />
  //                     </Form.Group>
  //                   </Col>
  //                 </Row>

  //                 <Row className="d-flex align-items-center">
  //                   <Col md={12}>
  //                     <Button
  //                       onClick={this.handleClose}
  //                       className="main-btn default-btn btn-right"
  //                     >
  //                       {" "}
  //                       Close
  //                     </Button>
  //                     <Button
  //                       onClick={() => this.handleSave("gen")}
  //                       className="main-btn default-btn btn-right"
  //                     >
  //                       {" "}
  //                       Save
  //                     </Button>
  //                   </Col>
  //                 </Row>
  //               </Container>
  //             </Modal.Body>
  //           </Modal>
  //         </Col>
  //       </Row>

  //       <Row>
  //         <Col md={12}>
  //           <Modal
  //             className="form-consent"
  //             show={this.state.notesPopup}
  //             // onHide={close}
  //             aria-labelledby="contained-modal-title"
  //           >
  //             <Modal.Body>
  //               <Container>
  //                 <Row className="d-flex align-items-center">
  //                   <Col md={12}>
  //                     <div className="form-consent-heading">
  //                       <Form.Label className="text-label">
  //                         Full Note
  //                       </Form.Label>
  //                     </div>
  //                   </Col>
  //                   <Col md={12}>
  //                     <p>{this.state.notesToShow}</p>
  //                   </Col>
  //                 </Row>

  //                 <Row className="d-flex align-items-center">
  //                   <Col md={12}>
  //                     <Button
  //                       onClick={this.handleNotesClose}
  //                       className="main-btn default-btn btn-right"
  //                     >
  //                       {" "}
  //                       Close
  //                     </Button>
  //                   </Col>
  //                 </Row>
  //               </Container>
  //             </Modal.Body>
  //           </Modal>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // }

  render() {
    return (
      //   <div className={Styles.ml_main}>
      //     <div className={`${Styles.tab_block} ${Styles.diversion_tab}`}>
      //       <Tabs
      //         defaultActiveKey="past-activities"
      //         id="uncontrolled-tab-example"
      //         className={Styles.bg_yellow}
      //       >
      //         {getAge(this.props.client) && (
      //           <Tab
      //             eventKey="past-activities"
      //             title={<span>Past Activities </span>}
      //             className="tab-table"
      //           >
      //             <Row>
      //               <Col md={12}>
      //                 <Table className="text-center">
      //                   <thead>
      //                     <tr>
      //                       <th>DATE</th>
      //                       <th>ACTIVITY</th>
      //                       <th>DETAILS</th>
      //                     </tr>
      //                   </thead>
      //                   <tbody>
      //                     {this.state.pastactivity.slice(0, 3).map((elem) => (
      //                       <tr>
      //                         <td>
      //                           {moment(elem.createdAt).format(DATE_FORMAT)}:{" "}
      //                           {moment(elem.createdAt).format(TIME_FORMAT)}
      //                         </td>
      //                         <td>
      //                           {elem.name === "CellVisits"
      //                             ? "Cell Visit"
      //                             : elem.name === "CommunityPatrols"
      //                             ? "Community Patrol"
      //                             : "Diversion Centre"}
      //                         </td>
      //                         <td className="p-0">
      //                           <Link
      //                             to={
      //                               TO_SERVICE_VIEW +
      //                               "/" +
      //                               elem.name +
      //                               "/" +
      //                               elem.id
      //                             }
      //                           >
      //                             <button
      //                               type="submit"
      //                               className="main-btn btn btn-primary view-btn"
      //                             >
      //                               VIEW
      //                             </button>
      //                           </Link>
      //                         </td>
      //                       </tr>
      //                     ))}
      //                   </tbody>
      //                 </Table>
      //               </Col>
      //             </Row>
      //           </Tab>
      //         )}
      //         {getUser().Role && getUser().Role.medicalHistory && (
      //           <Tab
      //             eventKey="medical-obsevations"
      //             title={<span>Medical Observations</span>}
      //             className="tab-table"
      //           >
      //             <Row>
      //               <Col md={3}></Col>
      //               <Col md={3}></Col>
      //               <Col md={3}></Col>
      //               <Col md={3}>
      //                 {this.props.match.params.id && (
      //                   <button
      //                     type="button"
      //                     onClick={(e) =>
      //                       this.setState({ show: true, notes: "" })
      //                     }
      //                     className="main-btn btn btn-primary orange-btn view-btn pull-right"
      //                   >
      //                     Add New
      //                   </button>
      //                 )}
      //               </Col>
      //               <Col md={12}>
      //                 <Table className="text-center">
      //                   <thead>
      //                     <tr>
      //                       <th>DATE</th>
      //                       <th>TIME</th>
      //                       <th>NOTE</th>
      //                       <th>CREATED BY</th>
      //                     </tr>
      //                   </thead>
      //                   <tbody>
      //                     {this.props.notes &&
      //                       this.props.notes.map((note) => (
      //                         <>
      //                           {note.notesType == "medical" && (
      //                             <tr>
      //                               <td>
      //                                 {moment(note.createdAt).format(DATE_FORMAT)}
      //                               </td>
      //                               <td>
      //                                 {moment(note.createdAt).format(TIME_FORMAT)}
      //                               </td>
      //                               <td onClick={() => this.fullNote(note.notes)}>
      //                                 {this.wordWrap(note.notes)}
      //                               </td>
      //                               <td className="p-0">{note.User.name}</td>
      //                             </tr>
      //                           )}
      //                         </>
      //                       ))}
      //                   </tbody>
      //                 </Table>
      //               </Col>
      //             </Row>
      //           </Tab>
      //         )}
      //         {getUser().Role && getUser().Role.observations && (
      //           <Tab
      //             eventKey="general-observations"
      //             title={<span>File Notes</span>}
      //             className="tab-table"
      //           >
      //             <Row>
      //               <Col md={3}></Col>
      //               <Col md={3}></Col>
      //               <Col md={3}></Col>
      //               <Col md={3}>
      //                 {this.props.match.params.id && (
      //                   <button
      //                     type="button"
      //                     onClick={(e) =>
      //                       this.setState({ show2: true, notes: "" })
      //                     }
      //                     className="main-btn btn btn-primary orange-btn view-btn pull-right"
      //                   >
      //                     Add New
      //                   </button>
      //                 )}
      //               </Col>
      //               <Col md={12}>
      //                 <Table className="text-center">
      //                   <thead>
      //                     <tr>
      //                       <th>DATE</th>
      //                       <th>TIME</th>
      //                       <th>NOTE</th>
      //                       <th>CREATED BY</th>
      //                     </tr>
      //                   </thead>
      //                   <tbody>
      //                     {this.props.notes &&
      //                       this.props.notes.map((note) => (
      //                         <>
      //                           {note.notesType == "general" && (
      //                             <tr>
      //                               <td>
      //                                 {moment(note.createdAt).format(DATE_FORMAT)}
      //                               </td>
      //                               <td>
      //                                 {moment(note.createdAt).format(TIME_FORMAT)}
      //                               </td>
      //                               <td onClick={() => this.fullNote(note.notes)}>
      //                                 {this.wordWrap(note.notes)}
      //                               </td>
      //                               <td className="p-0">{note.User.name}</td>
      //                             </tr>
      //                           )}
      //                         </>
      //                       ))}
      //                   </tbody>
      //                 </Table>
      //               </Col>
      //             </Row>
      //           </Tab>
      //         )}
      //       </Tabs>
      //       <Row>
      //         <Col md={12}>
      //           <Modal
      //             className="form-consent"
      //             show={this.state.show}
      //             // onHide={close}
      //             aria-labelledby="contained-modal-title"
      //           >
      //             <Modal.Body>
      //               <Container>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={12}>
      //                     <div className="form-consent-heading">
      //                       <Form.Label className="text-label">
      //                         Write Medical Observation
      //                       </Form.Label>
      //                     </div>
      //                   </Col>
      //                   <Col md={12}>
      //                     <Form.Group controlId="exampleForm.ControlTextarea1">
      //                       <Form.Control
      //                         value={this.state.notes}
      //                         onChange={(e) => this.onChangeMedical(e)}
      //                         name="notes"
      //                         as="textarea"
      //                         rows={2}
      //                       />
      //                     </Form.Group>
      //                   </Col>
      //                 </Row>

      //                 <Row className="d-flex align-items-center">
      //                   <Col md={12}>
      //                     <Button
      //                       onClick={this.handleClose}
      //                       className="main-btn default-btn btn-right"
      //                     >
      //                       {" "}
      //                       Close
      //                     </Button>
      //                     <Button
      //                       onClick={() => this.handleSave("med")}
      //                       className="main-btn default-btn btn-right"
      //                     >
      //                       {" "}
      //                       Save
      //                     </Button>
      //                   </Col>
      //                 </Row>
      //               </Container>
      //             </Modal.Body>
      //           </Modal>
      //         </Col>
      //       </Row>
      //       <Row>
      //         <Col md={12}>
      //           <Modal
      //             className="form-consent"
      //             show={this.state.show2}
      //             // onHide={close}
      //             aria-labelledby="contained-modal-title"
      //           >
      //             <Modal.Body>
      //               <Container>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={12}>
      //                     <div className="form-consent-heading">
      //                       <Form.Label className="text-label">
      //                         Write Note
      //                       </Form.Label>
      //                     </div>
      //                   </Col>
      //                   <Col md={12}>
      //                     <Form.Group controlId="exampleForm.ControlTextarea1">
      //                       <Form.Control
      //                         value={this.state.notes}
      //                         onChange={(e) => this.onChangeMedical(e)}
      //                         name="notes"
      //                         as="textarea"
      //                         rows={2}
      //                       />
      //                     </Form.Group>
      //                   </Col>
      //                 </Row>

      //                 <Row className="d-flex align-items-center">
      //                   <Col md={12}>
      //                     <Button
      //                       onClick={this.handleClose}
      //                       className="main-btn default-btn btn-right"
      //                     >
      //                       {" "}
      //                       Close
      //                     </Button>
      //                     <Button
      //                       onClick={() => this.handleSave("gen")}
      //                       className="main-btn default-btn btn-right"
      //                     >
      //                       {" "}
      //                       Save
      //                     </Button>
      //                   </Col>
      //                 </Row>
      //               </Container>
      //             </Modal.Body>
      //           </Modal>
      //         </Col>
      //       </Row>

      //       <Row>
      //         <Col md={12}>
      //           <Modal
      //             className="form-consent"
      //             show={this.state.notesPopup}
      //             // onHide={close}
      //             aria-labelledby="contained-modal-title"
      //           >
      //             <Modal.Body>
      //               <Container>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={12}>
      //                     <div className="form-consent-heading">
      //                       <Form.Label className="text-label">
      //                         Full Note
      //                       </Form.Label>
      //                     </div>
      //                   </Col>
      //                   <Col md={12}>
      //                     <p>{this.state.notesToShow}</p>
      //                   </Col>
      //                 </Row>

      //                 <Row className="d-flex align-items-center">
      //                   <Col md={12}>
      //                     <Button
      //                       onClick={this.handleNotesClose}
      //                       className="main-btn default-btn btn-right"
      //                     >
      //                       {" "}
      //                       Close
      //                     </Button>
      //                   </Col>
      //                 </Row>
      //               </Container>
      //             </Modal.Body>
      //           </Modal>
      //         </Col>
      //       </Row>
      //     </div>
      //   </div>

      <div className={Styles.ml_main}>
        {this.props.match.params.id && (
          <div className={`${Styles.tab_block} ${Styles.diversion_tab}`}>
            <div className={Styles.bg_yellow}>
              <ul
                className={`nav nav-tabs ${Styles.nav} ${Styles.nav_tabs}`}
                role="tablist"
              >
                <li
                  className={Styles.nav_item}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.handleTab(e.target.id)}
                >
                  <a
                    //   className={`${Styles.nav_link} ${Styles.active}`}
                    className={
                      this.state.activeTab == 1
                        ? `${Styles.nav_link} ${Styles.active}`
                        : `${Styles.nav_link}`
                    }
                    data-bs-toggle="tab"
                    //   href="#tab1"
                    id="1"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Past Activities
                  </a>
                </li>
                <li
                  className={Styles.nav_item}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.handleTab(e.target.id)}
                >
                  <a
                    //   className="nav-link"
                    className={
                      this.state.activeTab == 2
                        ? `${Styles.nav_link} ${Styles.active}`
                        : `${Styles.nav_link}`
                    }
                    data-bs-toggle="tab"
                    //   href="#tab2"
                    id="2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Medical Observations
                  </a>
                </li>
                <li
                  className={Styles.nav_item}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.handleTab(e.target.id)}
                >
                  <a
                    //   className="nav-link"
                    className={
                      this.state.activeTab == 3
                        ? `${Styles.nav_link} ${Styles.active}`
                        : `${Styles.nav_link}`
                    }
                    data-bs-toggle="tab"
                    //   href="#tab3"
                    id="3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    File Notes
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        {this.props.match.params.id && (
          <div className="tab-content" style={{ paddingBottom: "50px" }}>
            <div id="tab1" className="tab-pane active">
              {this.state.activeTab == 2 && (
                <>
                  {this.props.match.params.id && (
                    <div
                      className={`text-end`}
                      style={{
                        textAlign: "end",
                        paddingRight: "50px",
                        paddingTop: "25px",
                      }}
                    >
                      <button
                        className={`${Styles.btn} ${Styles.btn_yellow}`}
                        //   onClick={this.downloadReport}
                        type="button"
                        onClick={(e) =>
                          this.setState({ show: true, notes: "" })
                        }
                      >
                        Add New
                      </button>
                    </div>
                  )}
                </>
              )}
              {this.state.activeTab == 3 && (
                <>
                  {this.props.match.params.id && (
                    <div
                      className={`text-end`}
                      style={{
                        textAlign: "end",
                        paddingRight: "50px",
                        paddingTop: "25px",
                      }}
                    >
                      <button
                        className={`${Styles.btn} ${Styles.btn_yellow}`}
                        //   onClick={this.downloadReport}
                        type="button"
                        onClick={(e) =>
                          this.setState({ show2: true, notes: "" })
                        }
                      >
                        Add New
                      </button>
                    </div>
                  )}
                </>
              )}
              <div
                className="table-responsive"
                style={{ overflowX: "auto", position: "relative" }}
              >
                <table
                  className={`${Styles.table_custom} ${Styles.pastActivities_table}`}
                >
                  <thead>
                    {this.state.activeTab == 1 ? (
                      <tr>
                        <th>Date</th>
                        <th>Activity</th>
                        <th>Details</th>
                      </tr>
                    ) : this.state.activeTab == 2 ? (
                      <tr>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>NOTE</th>
                        <th>CREATED BY</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>NOTE</th>
                        <th>CREATED BY</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {this.state.activeTab == 1 && (
                      <>
                        {this.state.pastactivity
                          .slice(0, 3)
                          .map((elem, index) => (
                            <tr key={index}>
                              <td>
                                {moment(elem.createdAt).format(DATE_FORMAT)}:{" "}
                                {moment(elem.createdAt).format(TIME_FORMAT)}
                              </td>
                              <td>
                                {elem.name === "CellVisits"
                                  ? "Cell Visit"
                                  : elem.name === "CommunityPatrols"
                                    ? "Community Patrol"
                                    : "Diversion Centre"}
                              </td>
                              <td>
                                <Link
                                  to={
                                    !getUser().Role.admin
                                      ? TO_SERVICE_VIEW +
                                      "/" +
                                      elem.name +
                                      "/" +
                                      elem.id
                                      : TO_DIVERSION_CENTRE +
                                      "/edit/" +
                                      elem.id
                                  }
                                >
                                  <button
                                    type="submit"
                                    className={`${Styles.btn} ${Styles.btn_gray}`}
                                  >
                                    View
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                    {/* {this.state.activeTab == 2 && (
                    <>
                      {this.props.match.params.id && (
                        <div
                          className={`text-end`}
                          style={{ textAlign: "end" }}
                        >
                          <button
                            className={`${Styles.btn} ${Styles.btn_yellow}`}
                            //   onClick={this.downloadReport}
                            type="button"
                            onClick={(e) =>
                              this.setState({ show: true, notes: "" })
                            }
                          >
                            Add New
                          </button>
                        </div>
                      )}
                    </>
                  )} */}
                    {this.state.activeTab == 2 ? (
                      <>
                        {getUser().Role && getUser().Role.medicalHistory && (
                          <>
                            {this.props.notes &&
                              this.props.notes.map((note) => (
                                <>
                                  {note.notesType == "medical" && (
                                    <tr>
                                      <td>
                                        {moment(note.createdAt).format(
                                          DATE_FORMAT
                                        )}
                                      </td>
                                      <td>
                                        {moment(note.createdAt).format(
                                          TIME_FORMAT
                                        )}
                                      </td>
                                      <td
                                        onClick={() =>
                                          this.fullNote(note.notes)
                                        }
                                      >
                                        {this.wordWrap(note.notes)}
                                      </td>
                                      <td>{note.User.name}</td>
                                    </tr>
                                  )}
                                </>
                              ))}
                          </>
                        )}
                      </>
                    ) : null}
                    {this.state.activeTab == 3
                      ? getUser().Role &&
                      getUser().Role.observations && (
                        <>
                          {this.props.notes &&
                            this.props.notes.map((note) => (
                              <>
                                {note.notesType == "general" && (
                                  <tr>
                                    <td>
                                      {moment(note.createdAt).format(
                                        DATE_FORMAT
                                      )}
                                    </td>
                                    <td>
                                      {moment(note.createdAt).format(
                                        TIME_FORMAT
                                      )}
                                    </td>
                                    <td
                                      onClick={() =>
                                        this.fullNote(note.notes)
                                      }
                                    >
                                      {this.wordWrap(note.notes)}
                                    </td>
                                    <td>{note.User.name}</td>
                                  </tr>
                                )}
                              </>
                            ))}
                        </>
                      )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            {/* {this.state.activeTab == 2 ? (
            <div
              id="tab2"
              className={
                this.state.activeTab == 2
                  ? `${Styles.tab_pane} ${Styles.fade} ${Styles.show} ${Styles.active}`
                  : `${Styles.tab_pane} ${Styles.fade}`
              }
            >
              <div className="mt-3">
                <h2>Medical Observations</h2>
              </div>
            </div>
          ) : null} */}
            {/* {this.state.activeTab == 3 ? (
            <div
              id="tab3"
              className={
                this.state.activeTab == 3
                  ? `${Styles.tab_pane} ${Styles.fade} ${Styles.show}`
                  : `${Styles.tab_pane} ${Styles.fade}`
              }
            >
              <div className="mt-3">
                <h2>File Notes</h2>
              </div>
            </div>
          ) : null} */}
            <Row>
              <Col md={12}>
                <Modal
                  className="form-consent"
                  show={this.state.show}
                  // onHide={close}
                  aria-labelledby="contained-modal-title"
                >
                  <Modal.Body>
                    <Container>
                      <Row className="d-flex align-items-center">
                        <Col md={12}>
                          <div className="form-consent-heading">
                            <Form.Label className="text-label">
                              Write Medical Observation
                            </Form.Label>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              className={`${Styles.form_control}`}
                              value={this.state.notes}
                              onChange={(e) => this.onChangeMedical(e)}
                              name="notes"
                              as="textarea"
                              rows={2}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* <Row className="d-flex align-items-center">
                      <Col md={12}>
                        <Button
                          onClick={this.handleClose}
                          // className="main-btn default-btn btn-right"
                          className={`${Styles.btn} ${Styles.btn_yellow}`}
                        >
                          {" "}
                          Close
                        </Button>
                        <Button
                          onClick={() => this.handleSave("med")}
                          className="main-btn default-btn btn-right"
                        >
                          {" "}
                          Save
                        </Button>
                      </Col>
                    </Row> */}
                      <div
                        className={`${Styles.report_btn} text-end`}
                        style={{ textAlign: "right" }}
                      >
                        <button
                          onClick={this.handleClose}
                          className={`${Styles.btn} ${Styles.btn_red}`}
                        >
                          {" "}
                          Close
                        </button>
                        <button
                          onClick={() => this.handleSave("med")}
                          className={`${Styles.btn} ${Styles.btn_yellow}`}
                        >
                          {" "}
                          Save
                        </button>
                      </div>
                    </Container>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Modal
                  className="form-consent"
                  show={this.state.show2}
                  // onHide={close}
                  aria-labelledby="contained-modal-title"
                >
                  <Modal.Body>
                    <Container>
                      <Row className="d-flex align-items-center">
                        <Col md={12}>
                          <div className="form-consent-heading">
                            <Form.Label className="text-label">
                              Write Note
                            </Form.Label>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              value={this.state.notes}
                              onChange={(e) => this.onChangeMedical(e)}
                              name="notes"
                              as="textarea"
                              rows={2}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* <Row className="d-flex align-items-center">
                      <Col md={12}>
                        <Button
                          onClick={this.handleClose}
                          className="main-btn default-btn btn-right"
                        >
                          {" "}
                          Close
                        </Button>
                        <Button
                          onClick={() => this.handleSave("gen")}
                          className="main-btn default-btn btn-right"
                        >
                          {" "}
                          Save
                        </Button>
                      </Col>
                    </Row> */}
                      <div
                        className={`${Styles.report_btn} text-end`}
                        style={{ textAlign: "right" }}
                      >
                        <button
                          onClick={this.handleClose}
                          className={`${Styles.btn} ${Styles.btn_red}`}
                        >
                          {" "}
                          Close
                        </button>
                        <button
                          onClick={() => this.handleSave("gen")}
                          className={`${Styles.btn} ${Styles.btn_yellow}`}
                        >
                          {" "}
                          Save
                        </button>
                      </div>
                    </Container>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Modal
                  className="form-consent"
                  show={this.state.notesPopup}
                  // onHide={close}
                  aria-labelledby="contained-modal-title"
                >
                  <Modal.Body>
                    <Container>
                      <Row className="d-flex align-items-center">
                        <Col md={12}>
                          <div className="form-consent-heading">
                            <Form.Label className="text-label">
                              Full Note
                            </Form.Label>
                          </div>
                        </Col>
                        <Col md={12}>
                          <p>{this.state.notesToShow}</p>
                        </Col>
                      </Row>

                      {/* <Row className="d-flex align-items-center">
                      <Col md={12}>
                        <Button
                          onClick={this.handleNotesClose}
                          className="main-btn default-btn btn-right"
                        >
                          {" "}
                          Close
                        </Button>
                      </Col>
                    </Row> */}
                      <div
                        className={`${Styles.report_btn} text-end`}
                        style={{ textAlign: "right" }}
                      >
                        <button
                          onClick={this.handleNotesClose}
                          className={`${Styles.btn} ${Styles.btn_red}`}
                        >
                          {" "}
                          Close
                        </button>
                      </div>
                    </Container>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    searchClients: state.client.searchClients,
    client: state.client.client,
    updated: state.client.updated,
    created: state.client.created,
    note: state.notes.clientNote,
    notes: state.notes.clientNotes,
  };
};
export default connect(stateMap)(ThreeTabs);
// export default ThreeTabs

import React, { useEffect, useState, Fragment } from "react";
import HomeIcon from "@material-ui/icons/Home";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationIcon from "@material-ui/icons/LocationCity";
import CategoryIcon from "@material-ui/icons/Category";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { Link, useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import classNames from "classnames";
// import "./sidebar.scss";
import Styles from "../css/default.module.css";
import {
  TO_CLIENTS,
  TO_DASHBOARD,
  TO_ADMIN,
  TO_COMMUNITY_PATROL,
  TO_CELL_VISIT,
  TO_REPORT_DIVERSION,
  TO_REPORT_CELLVISIT,
  TO_REPORT_COMMUNITYPETROL,
  TO_DIVERSION_CENTRE,
  TO_ADMIN_LIST,
  TO_CREATE_CLIENTS,
  TO_CONTACT,
  TO_REPORT_FORM,
  TO_LOCATION,
  TO_CATEGORY,
} from "../../../helpers/constants";
import { NEXTVIEW_URL, BASE_URL } from "../../../api/constants";
import {
  markActive,
  getUser,
  internetConnected,
  toast,
} from "../../../helpers/utility";
import { PrepareSSO } from "../../../api/user/user-api";
const drawerWidth = "9.78005115089514rem";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
}));
const Sidebar = ({ children, isSidebarOpen }) => {
  // const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(isSidebarOpen);

  useEffect(() => {
    setOpen(isSidebarOpen);
  }, [isSidebarOpen]);

  const beginSSO = () => {
    PrepareSSO().then((resp) => {
      const ssoToken = resp.data.data.token;

      window.location.href =
        NEXTVIEW_URL +
        "/?token=" +
        ssoToken +
        "&logoutUrl=" +
        window.location.href +
        "&server=" +
        BASE_URL;
    });
  };

  // return (
  //   <>
  //     <Drawer
  //       variant="permanent"
  //       className={clsx(classes.drawer, {
  //         [classes.drawerOpen]: open,
  //         [classes.drawerClose]: !open,
  //       })}
  //       classes={{
  //         paper: clsx({
  //           [classes.drawerOpen]: open,
  //           [classes.drawerClose]: !open,
  //         }),
  //       }}
  //     >
  //       <Divider />
  //       <List>
  //         {["Dashboard"].map((text) => (
  //           <Link
  //             key={text}
  //             onClick={() =>
  //               !internetConnected()
  //                 ? toast("You are not connected to internet", "error")
  //                 : history.push(TO_DASHBOARD)
  //             }
  //             to={"#"}
  //           >
  //             <ListItem
  //               className={classNames({
  //                 active: history.location.pathname === TO_DASHBOARD,
  //               })}
  //               button
  //               key={text}
  //             >
  //               <ListItemIcon>{<HomeIcon />}</ListItemIcon>
  //               <ListItemText primary={text} />
  //             </ListItem>
  //           </Link>
  //         ))}
  //         {getUser().Role &&
  //           getUser().Role.clients &&
  //           ["Clients"].map((text) => (
  //             <Fragment key={text}>
  //               <Link to={"#"}>
  //                 <Accordion
  //                   defaultActiveKey={
  //                     markActive(TO_CLIENTS, history) ||
  //                     markActive(TO_CREATE_CLIENTS, history)
  //                       ? "1"
  //                       : ""
  //                   }
  //                   className="button-toggle__custom"
  //                 >
  //                   <Accordion.Toggle as={Button} variant="link" eventKey="1">
  //                     <ListItem
  //                       className={classNames({
  //                         active:
  //                           history.location.pathname.indexOf("/service-view") >
  //                           -1,
  //                       })}
  //                       button
  //                       onClick={() =>
  //                         !internetConnected()
  //                           ? toast(
  //                               "You are not connected to internet",
  //                               "error"
  //                             )
  //                           : history.push(TO_CLIENTS)
  //                       }
  //                       key={text}
  //                     >
  //                       <ListItemIcon>{<PersonIcon />}</ListItemIcon>
  //                       <ListItemText primary={text} />
  //                     </ListItem>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse eventKey="1">
  //                     <ul>
  //                       <li
  //                         className={classNames({
  //                           active: history.location.pathname === TO_CLIENTS,
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_CLIENTS)
  //                         }
  //                       >
  //                         List
  //                       </li>
  //                       <li
  //                         className={classNames({
  //                           active: markActive(TO_CREATE_CLIENTS, history),
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_CREATE_CLIENTS)
  //                         }
  //                       >
  //                         Add New
  //                       </li>
  //                     </ul>
  //                   </Accordion.Collapse>
  //                 </Accordion>
  //               </Link>
  //             </Fragment>
  //           ))}
  //         {["Contact"].map((text) => (
  //           <Fragment key={text}>
  //             <Link to={"#"}>
  //               <Accordion
  //                 defaultActiveKey={markActive(TO_CONTACT, history) ? "1" : ""}
  //                 className="button-toggle__custom"
  //               >
  //                 <Accordion.Toggle as={Button} variant="link" eventKey="1">
  //                   <ListItem
  //                     button
  //                     onClick={() =>
  //                       !internetConnected()
  //                         ? toast("You are not connected to internet", "error")
  //                         : history.push(TO_CONTACT)
  //                     }
  //                     key={text}
  //                   >
  //                     <ListItemIcon>{<PhoneAndroidIcon />}</ListItemIcon>
  //                     <ListItemText primary={text} />
  //                   </ListItem>
  //                 </Accordion.Toggle>
  //                 <Accordion.Collapse eventKey="1">
  //                   <ul>
  //                     <li
  //                       className={classNames({
  //                         active: history.location.pathname === TO_CONTACT,
  //                       })}
  //                       onClick={() =>
  //                         !internetConnected()
  //                           ? toast(
  //                               "You are not connected to internet",
  //                               "error"
  //                             )
  //                           : history.push(TO_CONTACT)
  //                       }
  //                     >
  //                       Add New
  //                     </li>
  //                   </ul>
  //                 </Accordion.Collapse>
  //               </Accordion>
  //             </Link>
  //           </Fragment>
  //         ))}
  //         {getUser().Role &&
  //           getUser().Role.divertionCenter &&
  //           ["Diversion Centre"].map((text) => (
  //             <Link
  //               key={text}
  //               onClick={() =>
  //                 !internetConnected()
  //                   ? toast("You are not connected to internet", "error")
  //                   : history.push(TO_DIVERSION_CENTRE)
  //               }
  //               to={"#"}
  //             >
  //               <ListItem
  //                 className={classNames({
  //                   active:
  //                     history.location.pathname.indexOf(TO_DIVERSION_CENTRE) >
  //                     -1,
  //                 })}
  //                 button
  //                 key={text}
  //               >
  //                 <ListItemIcon>{<CenterFocusWeakIcon />}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             </Link>
  //           ))}
  //         {/* {getUser().Role &&
  //           getUser().Role.cellVisits &&
  //           ["Cell Visits"].map((text) => (
  //             <Link
  //               key={text}
  //               onClick={() => history.push(TO_CELL_VISIT)}
  //               to={"#"}
  //             >
  //               <ListItem
  //                 className={classNames({
  //                   active:
  //                     history.location.pathname.indexOf(TO_CELL_VISIT) > -1,
  //                 })}
  //                 button
  //                 key={text}
  //               >
  //                 <ListItemIcon>{<DescriptionIcon />}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             </Link>
  //           ))} */}
  //         {/* {getUser().Role &&
  //           getUser().Role.communityPatrol &&
  //           ["Community Patrol"].map((text) => (
  //             <Link
  //               key={text}
  //               onClick={() =>
  //                 !internetConnected()
  //                   ? toast("You are not connected to internet", "error")
  //                   : history.push(TO_COMMUNITY_PATROL)
  //               }
  //               to={"#"}
  //             >
  //               <ListItem
  //                 className={classNames({
  //                   active:
  //                     history.location.pathname.indexOf(TO_COMMUNITY_PATROL) >
  //                     -1,
  //                 })}
  //                 button
  //                 key={text}
  //               >
  //                 <ListItemIcon>{<HomeIcon />}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             </Link>
  //           ))} */}
  //         {getUser().Role &&
  //           getUser().Role.reports &&
  //           ["Reports"].map((text) => (
  //             <Fragment key={text}>
  //               <Link to={"#"}>
  //                 <Accordion
  //                   defaultActiveKey={
  //                     markActive(TO_REPORT_FORM, history) ||
  //                     markActive(TO_REPORT_DIVERSION, history) ||
  //                     markActive(TO_REPORT_CELLVISIT, history) ||
  //                     markActive(TO_REPORT_COMMUNITYPETROL, history)
  //                       ? "1"
  //                       : ""
  //                   }
  //                   className="button-toggle__custom"
  //                 >
  //                   <Accordion.Toggle as={Button} variant="link" eventKey="1">
  //                     <ListItem
  //                       className={classNames({
  //                         active:
  //                           history.location.pathname.indexOf(TO_REPORT_FORM) >
  //                           -1,
  //                       })}
  //                       key={text}
  //                       button
  //                     >
  //                       <ListItemIcon>{<DescriptionIcon />}</ListItemIcon>
  //                       <ListItemText primary={text} />
  //                     </ListItem>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse eventKey="1">
  //                     <ul>
  //                       <li
  //                         className={classNames({
  //                           active:
  //                             history.location.pathname === TO_REPORT_FORM,
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_REPORT_FORM)
  //                         }
  //                       >
  //                         All Reports
  //                       </li>
  //                       <li
  //                         className={classNames({
  //                           active: markActive(TO_REPORT_DIVERSION, history),
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_REPORT_DIVERSION)
  //                         }
  //                       >
  //                         Diversion Center Reports
  //                       </li>
  //                       {/* <li
  //                         className={classNames({
  //                           active: markActive(TO_REPORT_CELLVISIT, history),
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_REPORT_CELLVISIT)
  //                         }
  //                       >
  //                         Cell Visit Reports
  //                       </li> */}
  //                       {/* <li
  //                         className={classNames({
  //                           active: markActive(
  //                             TO_REPORT_COMMUNITYPETROL,
  //                             history
  //                           ),
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_REPORT_COMMUNITYPETROL)
  //                         }
  //                       >
  //                         Community Patrol Reports{" "}
  //                       </li> */}
  //                     </ul>
  //                   </Accordion.Collapse>
  //                 </Accordion>
  //               </Link>
  //             </Fragment>
  //           ))}
  //         {getUser().Role &&
  //           getUser().Role.reports &&
  //           ["Locations"].map((text) => (
  //             <Link key={text} to={TO_LOCATION}>
  //               <ListItem
  //                 className={classNames({
  //                   active: history.location.pathname.indexOf(TO_LOCATION) > -1,
  //                 })}
  //                 key={text}
  //                 button
  //               >
  //                 <ListItemIcon>{<LocationIcon />}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             </Link>
  //           ))}
  //         {getUser().Role &&
  //           getUser().Role.reports &&
  //           ["Categories"].map((text) => (
  //             <Link key={text} to={TO_CATEGORY}>
  //               <ListItem
  //                 className={classNames({
  //                   active: history.location.pathname.indexOf(TO_CATEGORY) > -1,
  //                 })}
  //                 key={text}
  //                 button
  //               >
  //                 <ListItemIcon>{<CategoryIcon />}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             </Link>
  //           ))}
  //         {getUser().Role &&
  //           getUser().Role.admin &&
  //           ["Admin"].map((text) => (
  //             <Fragment key={text}>
  //               <Link to={"#"}>
  //                 <Accordion
  //                   defaultActiveKey={
  //                     markActive(TO_ADMIN, history) ||
  //                     markActive(TO_ADMIN_LIST, history)
  //                       ? "1"
  //                       : ""
  //                   }
  //                   className="button-toggle__custom"
  //                 >
  //                   <Accordion.Toggle as={Button} variant="link" eventKey="1">
  //                     <ListItem
  //                       button
  //                       onClick={() =>
  //                         !internetConnected()
  //                           ? toast(
  //                               "You are not connected to internet",
  //                               "error"
  //                             )
  //                           : history.push(TO_ADMIN_LIST)
  //                       }
  //                       key={text}
  //                     >
  //                       <ListItemIcon>{<CenterFocusWeakIcon />}</ListItemIcon>
  //                       <ListItemText primary={text} />
  //                     </ListItem>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse eventKey="1">
  //                     <ul>
  //                       <li
  //                         className={classNames({
  //                           active: history.location.pathname === TO_ADMIN_LIST,
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_ADMIN_LIST)
  //                         }
  //                       >
  //                         List
  //                       </li>
  //                       <li
  //                         className={classNames({
  //                           active: markActive(TO_ADMIN, history),
  //                         })}
  //                         onClick={() =>
  //                           !internetConnected()
  //                             ? toast(
  //                                 "You are not connected to internet",
  //                                 "error"
  //                               )
  //                             : history.push(TO_ADMIN)
  //                         }
  //                       >
  //                         Add New
  //                       </li>
  //                     </ul>
  //                   </Accordion.Collapse>
  //                 </Accordion>
  //               </Link>
  //             </Fragment>
  //           ))}
  //         {/* {getUser().Role && getUser().Role.admin && ['NextView'].map((text) => (
  //                       <Link key={text} onClick={() => !internetConnected() ? toast("You are not connected to internet", "error") : beginSSO()} to={'#'} >
  //                           <ListItem button key={text}>
  //                               <ListItemIcon>{<LibraryBooksIcon />}</ListItemIcon>
  //                               <ListItemText primary={text} />
  //                           </ListItem>
  //                       </Link>
  //                   ))} */}
  //       </List>
  //     </Drawer>
  //     <main className={classes.content}>
  //       <div className={classes.toolbar} />
  //       {children}
  //     </main>
  //   </>
  // );

  return (
    <>
      <aside id={Styles.sidebar_wrapper}>
        <div className={Styles.sidebar_brand}>
          <Link onClick={() => history.push(TO_DASHBOARD)} to={"#"}>
            <img src={require("../../../assets/images2/logo.png")} alt="Logo" />
          </Link>
        </div>
        <ul className={Styles.sidebar_nav}>
          <li>
            {["Home"].map((text) => (
              <Link
                key={text}
                onClick={() =>
                  !internetConnected()
                    ? toast("You are not connected to internet", "error")
                    : history.push(TO_DASHBOARD)
                }
                to={"#"}
                className={
                  history.location.pathname === TO_DASHBOARD
                    ? Styles.active
                    : ""
                }
              >
                <div
                  className={
                    history.location.pathname === TO_DASHBOARD
                      ? Styles.active
                      : ""
                  }
                >
                  <img
                    src={require("../../../assets/images2/home.png")}
                    alt="Logo"
                  />
                  {text}
                </div>
              </Link>
            ))}
          </li>
          <li>
            {getUser().Role &&
              getUser().Role.clients &&
              ["Clients"].map((text) => (
                <Fragment key={text}>
                  <Link to={"#"} style={{ padding: "0", border: "none" }}>
                    <Accordion
                      style={{ width: "100%", height: "100%" }}
                      defaultActiveKey={
                        markActive(TO_CLIENTS, history) ||
                        markActive(TO_CREATE_CLIENTS, history)
                          ? "1"
                          : ""
                      }
                      className="button-toggle__custom"
                    >
                      <Accordion.Toggle
                        variant="link"
                        eventKey="1"
                        style={{
                          paddingLeft: 0,
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div
                          className={
                            history.location.pathname.indexOf("/service-view") >
                            -1
                              ? Styles.active
                              : ""
                          }
                          style={{
                            display: "flex",
                            paddingTop: "11px",
                            paddingBottom: "11px",
                            paddingRight: "14px",
                            paddingLeft: "14px",
                          }}
                          // onClick={() =>
                          //   !internetConnected()
                          //     ? toast(
                          //         "You are not connected to internet",
                          //         "error"
                          //       )
                          //     : history.push(TO_CLIENTS)
                          // }
                          key={text}
                        >
                          <img
                            src={require("../../../assets/images2/clients.png")}
                            alt="Icon"
                          />
                          {text}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <ul>
                          <div
                            style={{
                              backgroundColor:
                                history.location.pathname === TO_CLIENTS
                                  ? "#fdd817"
                                  : "",
                              borderRight:
                                history.location.pathname === TO_CLIENTS
                                  ? "6px solid"
                                  : "",
                              borderColor:
                                history.location.pathname === TO_CLIENTS
                                  ? "#d12329"
                                  : "",
                            }}
                          >
                            <li
                              className={classNames({
                                active:
                                  history.location.pathname === TO_CLIENTS,
                              })}
                              onClick={() =>
                                !internetConnected()
                                  ? toast(
                                      "You are not connected to internet",
                                      "error"
                                    )
                                  : history.push(TO_CLIENTS)
                              }
                              style={{
                                paddingTop: "11px",
                                paddingBottom: "11px",
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <img
                                src={require("../../../assets/images2/list.png")}
                              />
                              List
                            </li>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                history.location.pathname === TO_CREATE_CLIENTS
                                  ? "#fdd817"
                                  : "",
                              borderRight:
                                history.location.pathname === TO_CREATE_CLIENTS
                                  ? "6px solid"
                                  : "",
                              borderColor:
                                history.location.pathname === TO_CREATE_CLIENTS
                                  ? "#d12329"
                                  : "",
                            }}
                          >
                            <li
                              className={classNames({
                                active: markActive(TO_CREATE_CLIENTS, history),
                              })}
                              onClick={() =>
                                !internetConnected()
                                  ? toast(
                                      "You are not connected to internet",
                                      "error"
                                    )
                                  : history.push(TO_CREATE_CLIENTS)
                              }
                              style={{
                                paddingTop: "11px",
                                paddingBottom: "11px",
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <img
                                src={require("../../../assets/images2/add-button.png")}
                              />
                              Add New
                            </li>
                          </div>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </Link>
                </Fragment>
              ))}
          </li>
          <li>
            {["Contact"].map((text) => (
              <Fragment key={text}>
                <Link to={"#"} style={{ padding: "0", border: "none" }}>
                  <Accordion
                    style={{ width: "100%", height: "100%" }}
                    defaultActiveKey={
                      markActive(TO_CONTACT, history) ? "1" : ""
                    }
                    className="button-toggle__custom"
                  >
                    <Accordion.Toggle
                      style={{
                        paddingLeft: 0,
                        background: "transparent",
                        border: "none",
                        width: "100%",
                      }}
                      variant="link"
                      eventKey="1"
                    >
                      <div
                        className={
                          history.location.pathname.indexOf(
                            TO_DIVERSION_CENTRE
                          ) > -1
                            ? Styles.active
                            : ""
                        }
                        style={{
                          display: "flex",
                          paddingTop: "11px",
                          paddingBottom: "11px",
                          paddingRight: "14px",
                          paddingLeft: "14px",
                        }}
                        // onClick={() =>
                        //   !internetConnected()
                        //     ? toast(
                        //         "You are not connected to internet",
                        //         "error"
                        //       )
                        //     : history.push(TO_CONTACT)
                        // }
                        key={text}
                      >
                        <img
                          src={require("../../../assets/images2/contact.png")}
                          alt="Icon"
                        />
                        {text}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey="1"
                      style={{
                        borderRight:
                          history.location.pathname === TO_CONTACT
                            ? "6px solid"
                            : "",
                        borderColor:
                          history.location.pathname === TO_CONTACT
                            ? "#d12329"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            history.location.pathname === TO_CONTACT
                              ? "#fdd817"
                              : "",
                        }}
                      >
                        <ul
                          className={
                            history.location.pathname === TO_CONTACT > -1
                              ? Styles.active
                              : ""
                          }
                          // style={}
                        >
                          <li
                            // className={
                            //   history.location.pathname === TO_CONTACT > -1
                            //     ? Styles.active
                            //     : ""
                            // }
                            style={{
                              paddingTop: "11px",
                              paddingBottom: "11px",
                              paddingRight: "14px",
                              paddingLeft: "14px",
                            }}
                            onClick={() =>
                              !internetConnected()
                                ? toast(
                                    "You are not connected to internet",
                                    "error"
                                  )
                                : history.push(TO_CONTACT)
                            }
                          >
                            <img
                              src={require("../../../assets/images2/add-button.png")}
                            />
                            Add New
                          </li>
                        </ul>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </Link>
              </Fragment>
            ))}
          </li>
          <li>
            {getUser().Role &&
              getUser().Role.divertionCenter &&
              ["Diversion Centre"].map((text) => (
                <Link
                  key={text}
                  onClick={() =>
                    !internetConnected()
                      ? toast("You are not connected to internet", "error")
                      : history.push(TO_DIVERSION_CENTRE)
                  }
                  to={"#"}
                  className={
                    history.location.pathname.indexOf(TO_DIVERSION_CENTRE) > -1
                      ? Styles.active
                      : ""
                  }
                >
                  <div
                    className={
                      history.location.pathname.indexOf(TO_DIVERSION_CENTRE) >
                      -1
                        ? Styles.active
                        : ""
                    }
                  >
                    <img
                      src={require("../../../assets/images2/diversion.png")}
                      alt="Icon"
                    />
                    {text}
                  </div>
                </Link>
              ))}
          </li>
          <li>
            {/* {getUser().Role &&
              getUser().Role.reports &&
              ["Reports"].map((text) => (
                <Fragment key={text}>
                  <Link to={"#"}>
                    <Accordion
                      defaultActiveKey={
                        markActive(TO_REPORT_FORM, history) ||
                        markActive(TO_REPORT_DIVERSION, history)
                          ? // markActive(TO_REPORT_CELLVISIT, history) ||
                            // markActive(TO_REPORT_COMMUNITYPETROL, history)
                            "1"
                          : ""
                      }
                    >
                      <Accordion.Toggle
                        variant="link"
                        eventKey="1"
                        style={{
                          paddingLeft: 0,
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div
                          className={
                            history.location.pathname.indexOf(TO_REPORT_FORM) >
                            -1
                              ? Styles.active
                              : ""
                          }
                          // onClick={() =>
                          //   !internetConnected()
                          //     ? toast(
                          //         "You are not connected to internet",
                          //         "error"
                          //       )
                          //     : history.push(TO_REPORT_FORM)
                          // }
                          key={text}
                        >
                          <img
                            src={require("../../../assets/images2/reports.png")}
                            alt="Icon"
                          />
                          {text}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <ul>
                          <li
                            className={classNames({
                              active:
                                history.location.pathname === TO_REPORT_FORM,
                            })}
                            onClick={() =>
                              !internetConnected()
                                ? toast(
                                    "You are not connected to internet",
                                    "error"
                                  )
                                : history.push(TO_REPORT_FORM)
                            }
                          >
                            All Reports
                          </li>
                          <li
                            className={classNames({
                              active: markActive(TO_REPORT_DIVERSION, history),
                            })}
                            onClick={() =>
                              !internetConnected()
                                ? toast(
                                    "You are not connected to internet",
                                    "error"
                                  )
                                : history.push(TO_REPORT_DIVERSION)
                            }
                          >
                            Diversion Center Reports
                          </li>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </Link>
                </Fragment>
              ))} */}

            {getUser().Role &&
              getUser().Role.reports &&
              ["Reports"].map((text) => (
                <Fragment key={text}>
                  <Link to={"#"} style={{ padding: "0", border: "none" }}>
                    <Accordion
                      style={{ width: "100%", height: "100%" }}
                      defaultActiveKey={
                        markActive(TO_REPORT_FORM, history) ||
                        markActive(TO_REPORT_DIVERSION, history)
                          ? "1"
                          : ""
                      }
                      className="button-toggle__custom"
                    >
                      <Accordion.Toggle
                        variant="link"
                        eventKey="1"
                        style={{
                          paddingLeft: 0,
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div
                          className={
                            history.location.pathname.indexOf(TO_REPORT_FORM) >
                            -1
                              ? Styles.active
                              : ""
                          }
                          style={{
                            display: "flex",
                            paddingTop: "11px",
                            paddingBottom: "11px",
                            paddingRight: "14px",
                            paddingLeft: "14px",
                          }}
                          // onClick={() =>
                          //   !internetConnected()
                          //     ? toast(
                          //         "You are not connected to internet",
                          //         "error"
                          //       )
                          //     : history.push(TO_CLIENTS)
                          // }
                          key={text}
                        >
                          <img
                            src={require("../../../assets/images2/reports.png")}
                            alt="Icon"
                          />
                          {text}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <ul>
                          <div
                            style={{
                              backgroundColor:
                                history.location.pathname === TO_REPORT_FORM
                                  ? "#fdd817"
                                  : "",
                              borderRight:
                                history.location.pathname === TO_REPORT_FORM
                                  ? "6px solid"
                                  : "",
                              borderColor:
                                history.location.pathname === TO_REPORT_FORM
                                  ? "#d12329"
                                  : "",
                            }}
                          >
                            <li
                              className={classNames({
                                active:
                                  history.location.pathname === TO_REPORT_FORM,
                              })}
                              onClick={() =>
                                !internetConnected()
                                  ? toast(
                                      "You are not connected to internet",
                                      "error"
                                    )
                                  : history.push(TO_REPORT_FORM)
                              }
                              style={{
                                paddingTop: "11px",
                                paddingBottom: "11px",
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <img
                                src={require("../../../assets/images2/all-reports.png")}
                              />
                              All Reports
                            </li>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                history.location.pathname ===
                                TO_REPORT_DIVERSION
                                  ? "#fdd817"
                                  : "",
                              borderRight:
                                history.location.pathname ===
                                TO_REPORT_DIVERSION
                                  ? "6px solid"
                                  : "",
                              borderColor:
                                history.location.pathname ===
                                TO_REPORT_DIVERSION
                                  ? "#d12329"
                                  : "",
                            }}
                          >
                            <li
                              className={classNames({
                                active: markActive(
                                  TO_REPORT_DIVERSION,
                                  history
                                ),
                              })}
                              onClick={() =>
                                !internetConnected()
                                  ? toast(
                                      "You are not connected to internet",
                                      "error"
                                    )
                                  : history.push(TO_REPORT_DIVERSION)
                              }
                              style={{
                                paddingTop: "11px",
                                paddingBottom: "11px",
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <img
                                src={require("../../../assets/images2/diversion-center-report.png")}
                              />
                              Diversion Center Reports
                            </li>
                          </div>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </Link>
                </Fragment>
              ))}
          </li>
          <li>
            {getUser().Role &&
              getUser().Role.reports &&
              ["Locations"].map((text) => (
                <Link
                  key={text}
                  to={TO_LOCATION}
                  className={
                    history.location.pathname.indexOf(TO_LOCATION) > -1
                      ? Styles.active
                      : ""
                  }
                >
                  <div
                    className={
                      history.location.pathname.indexOf(TO_LOCATION) > -1
                        ? Styles.active
                        : ""
                    }
                  >
                    <img
                      src={require("../../../assets/images2/locations.png")}
                      alt="Icon"
                    />
                    {text}
                  </div>
                </Link>
              ))}
          </li>
          <li>
            {getUser().Role &&
              getUser().Role.reports &&
              ["Categories"].map((text) => (
                <Link
                  key={text}
                  to={TO_CATEGORY}
                  className={
                    history.location.pathname.indexOf(TO_CATEGORY) > -1
                      ? Styles.active
                      : ""
                  }
                >
                  <div
                    className={
                      history.location.pathname.indexOf(TO_CATEGORY) > -1
                        ? Styles.active
                        : ""
                    }
                  >
                    <img
                      src={require("../../../assets/images2/categories.png")}
                      alt="Icon"
                    />
                    {text}
                  </div>
                </Link>
              ))}
          </li>
          <li>
            {getUser().Role &&
              getUser().Role.admin &&
              ["Admin"].map((text) => (
                <Fragment key={text}>
                  <Link to={"#"} style={{ padding: "0", border: "none" }}>
                    <Accordion
                      style={{ width: "100%", height: "100%" }}
                      defaultActiveKey={
                        markActive(TO_ADMIN, history) ||
                        markActive(TO_ADMIN_LIST, history)
                          ? "1"
                          : ""
                      }
                      className="button-toggle__custom"
                    >
                      <Accordion.Toggle
                        variant="link"
                        eventKey="1"
                        style={{
                          paddingLeft: 0,
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        {/* <ListItem
                          button
                          onClick={() =>
                            !internetConnected()
                              ? toast(
                                  "You are not connected to internet",
                                  "error"
                                )
                              : history.push(TO_ADMIN_LIST)
                          }
                          key={text}
                        >
                          <ListItemIcon>{<CenterFocusWeakIcon />}</ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItem> */}
                        <div
                          className={
                            history.location.pathname === TO_ADMIN_LIST
                              ? Styles.active
                              : ""
                          }
                          style={{
                            display: "flex",
                            paddingTop: "11px",
                            paddingBottom: "11px",
                            paddingRight: "14px",
                            paddingLeft: "14px",
                          }}
                          // onClick={() =>
                          //   !internetConnected()
                          //     ? toast(
                          //         "You are not connected to internet",
                          //         "error"
                          //       )
                          //     : history.push(TO_ADMIN_LIST)
                          // }
                          key={text}
                        >
                          <img
                            src={require("../../../assets/images2/admin.png")}
                            alt="Icon"
                          />
                          {text}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <ul>
                          <div
                            style={{
                              backgroundColor:
                                history.location.pathname === TO_ADMIN_LIST
                                  ? "#fdd817"
                                  : "",
                              borderRight:
                                history.location.pathname === TO_ADMIN_LIST
                                  ? "6px solid"
                                  : "",
                              borderColor:
                                history.location.pathname === TO_ADMIN_LIST
                                  ? "#d12329"
                                  : "",
                            }}
                          >
                            <li
                              className={classNames({
                                active:
                                  history.location.pathname === TO_ADMIN_LIST,
                              })}
                              onClick={() =>
                                !internetConnected()
                                  ? toast(
                                      "You are not connected to internet",
                                      "error"
                                    )
                                  : history.push(TO_ADMIN_LIST)
                              }
                              style={{
                                paddingTop: "11px",
                                paddingBottom: "11px",
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <img
                                src={require("../../../assets/images2/list.png")}
                              />
                              List
                            </li>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                history.location.pathname === TO_ADMIN
                                  ? "#fdd817"
                                  : "",
                              borderRight:
                                history.location.pathname === TO_ADMIN
                                  ? "6px solid"
                                  : "",
                              borderColor:
                                history.location.pathname === TO_ADMIN
                                  ? "#d12329"
                                  : "",
                            }}
                          >
                            <li
                              className={classNames({
                                active: markActive(TO_ADMIN, history),
                              })}
                              onClick={() =>
                                !internetConnected()
                                  ? toast(
                                      "You are not connected to internet",
                                      "error"
                                    )
                                  : history.push(TO_ADMIN)
                              }
                              style={{
                                paddingTop: "11px",
                                paddingBottom: "11px",
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <img
                                src={require("../../../assets/images2/add-button.png")}
                              />
                              Add New
                            </li>
                          </div>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </Link>
                </Fragment>
              ))}
          </li>
        </ul>
      </aside>
      {/* <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main> */}
    </>
  );
};

export default Sidebar;
